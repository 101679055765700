export function getToken() {
  return localStorage.getItem("admin_token");
}
export function setToken(token) {
  localStorage.setItem("admin_token", token);
}

export function setUserInfo(userInfo) {
  localStorage.setItem("admin_userInfo", JSON.stringify(userInfo));
}

export function getUserInfo() {
  const userInfo = JSON.parse(localStorage.setItem("admin_userInfo"));
  return userInfo;
}

export function getMenus() {
  const menuList = [
    {
      id: "1",
      name: "数据看板",
      pathName: "dashboard",
      path: "dashboard",
    },
    {
      id: "2",
      name: "订单管理",
      pathName: "order",
      path: "orderManage",
    },
    {
      id: "3",
      name: "打赏管理",
      pathName: "reward",
      path: "rewardManage",
    },
    {
      id: "4",
      name: "作品管理",
      pathName: "works",
      path: "worksManage",
    },
    {
      id: "5",
      name: "用户管理",
      pathName: "user",
      path: "userManage",
    },
    {
      id: "6",
      name: "分账管理",
      pathName: "independent",
      path: "independentManage",
    },
  ];
  localStorage.setItem("menuList", JSON.stringify(menuList));
  return menuList;
}
