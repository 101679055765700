import { createStore } from "vuex";
import { dynamicAddRoutes } from "../router";
export default createStore({
  state: {
    menuList: [],
  },
  getters: {},
  mutations: {
    SETMENULIST(state, data) {
      state.menuList = data;
      dynamicAddRoutes();
    },
  },
  actions: {},
  modules: {},
});
