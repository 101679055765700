<template>
  <div class="common-layout">
    <div class="top-header">
      <div class="logo">文艺青年AI管理平台</div>
      <div class="dis-flex-r">
        <span class="user-name"> admin,欢迎您！ </span>
        <el-tooltip
          class="item"
          effect="dark"
          content="退出系统"
          placement="bottom-end"
        >
          <span class="icon-btn" @click="loginOutFun">
            <img :src="out" alt="退出" />
            <!-- <SvgIcon name="out" className="icon-out" /> -->
          </span>
        </el-tooltip>
      </div>
    </div>
    <el-container>
      <el-aside width="200px">
        <LeftMenu />
      </el-aside>
      <el-main class="common-mian">
        <div class="common-container">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import LeftMenu from "./LeftManu.vue";
import out from "@/assets/icons/out.svg";
import { setToken, setUserInfo } from "@/units/user";
import { useRouter } from "vue-router";

const router = useRouter();
const loginOutFun = () => {
  setToken("");
  setUserInfo(null);
  router.replace("/login");
};
</script>

<style lang="scss" scoped>
.common-layout {
  height: 100%;
  width: 100%;
  background: #f7f9fc;
  ::v-deep .el-container {
    height: calc(100% - 66px);
    .common-mian {
      background: #f7f9fc;
      padding: 20px 0 0 20px;
      box-sizing: border-box;
      /* WebKit浏览器（Safari、Chrome等） */
      &::-webkit-scrollbar {
        width: 10px; /* 滚动条的宽度 */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9; /* 滚动条拖动块的背景颜色 */
        border-radius: 10px; /* 滚动条拖动块的圆角 */
      }

      &::-webkit-scrollbar-track {
        background-color: #f4f4f4; /* 滚动条背景颜色 */
      }

      /* Mozilla Firefox */
      &::-moz-scrollbar {
        width: 8px;
      }

      &::-moz-scrollbar-thumb {
        background-color: #d9d9d9; /* 滚动条拖动块的背景颜色 */
        border-radius: 8px;
      }

      &::-moz-scrollbar-track {
        background-color: #f4f4f4; /* 滚动条背景颜色 */
      }
      .common-container {
        width: 100%;
        min-height: 100%;
        background: #ffffff;
        padding: 16px;
        box-sizing: border-box;
      }
    }
  }
  .top-header {
    width: 100%;
    height: 66px;
    background: #ffffff;
    box-shadow: 0px 6px 12px 0px rgba(230, 233, 240, 0.3);
    box-sizing: border-box;
    display: flex;
    padding: 0 30px;
    align-items: center;
    justify-content: space-between;
    .dis-flex-r {
      display: flex;
    }
    .logo {
      font-weight: bold;
      color: #000;
    }
  }
}
</style>
