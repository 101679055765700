<template>
  <el-menu
    active-text-color="#fff"
    background-color="#fff"
    class="left-menu"
    text-color="#fff"
  >
    <div
      :index="menu.id"
      v-for="menu in menuList"
      :key="menu.id"
      @click="handleOpen(menu.path)"
      class="manu-item"
      :class="path === `/${menu.path}` ? 'active' : ''"
    >
      {{ menu.name }}
    </div>
  </el-menu>
</template>

<script setup>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
// import user from "@/assets/icons/user.svg";
// import order from "@/assets/icons/order.svg";
// import works from "@/assets/icons/works.svg";
// import reward from "@/assets/icons/reward.svg";
// import dashboard from "@/assets/icons/dashboard.svg";
// import independent from "@/assets/icons/independent.svg";
const store = useStore();
const route = useRoute();
const router = useRouter();
const menuList = computed(() => store.state.menuList);

const path = ref(route.path);
watch(route, (o) => {
  console.log(o.path);
  path.value = o.path;
});
const handleOpen = (path) => {
  router.push(path);
};
</script>

<style lang="scss" scoped>
.left-menu {
  padding: 10px 0;
  height: 100%;
  border: none;
  &:deep(.el-menu) {
  }
  .manu-item {
    text-align: center;
    color: #1f2e4d;
    padding: 10px 0;
    cursor: pointer;
    background: #fff;
    margin: 20px 0;
    letter-spacing: 1px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1f2e4d;
    &:hover {
      background: #e0edff;
      font-weight: 500;
    }
    &.active {
      background: #3377ff;
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>
