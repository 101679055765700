<!--
 * @version: 
 * @Author: YouHui
 * @Date: 2023-06-17 11:50:16
 * @LastEditors: Andy
 * @LastEditTime: 2023-06-23 13:23:09
 * @Descripttion: 
-->
<template>
  <router-view />
</template>

<script setup>
import { useStore } from "vuex";

const store = useStore();
const menuList = localStorage.getItem("menuList");
if (menuList) {
  store.commit("SETMENULIST", JSON.parse(menuList));
}
</script>

<style lang="scss">
#app {
  height: 100vh;
}
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
