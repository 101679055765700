<template>
  <div class="login-page">
    <div class="login-form">
      <p class="title">后台管理项目</p>
      <el-form label-width="80px">
        <el-form-item label="账号">
          <el-input v-model="loginForm.userName" style="width: 260px" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            v-model="loginForm.password"
            type="password"
            style="width: 260px"
          />
        </el-form-item>
      </el-form>
      <div class="login-btn">
        <el-button @click="handleLogin" type="primary">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { login } from "@/servers/login";
import { getMenus, setToken, setUserInfo } from "@/units/user";

const router = useRouter();
const store = useStore();
const loginForm = ref({
  userName: "",
  password: "",
});
const handleLogin = async () => {
  const res = await login(loginForm.value);
  if (res.code === "200") {
    setToken(res.data.token);
    setUserInfo(res.data.user);
    const menuList = getMenus();
    store.commit("SETMENULIST", menuList);
    router.replace("/");
  }
};
</script>

<style lang="scss" scoped>
.login-page {
  background: url("../../assets/image/bg.png") no-repeat 100% 100%;
  background-size: cover;
  width: 100%;
  height: 100%;
  .login-form {
    width: 500px;
    height: 300px;
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 50px;
    box-shadow: 0 0 20px 5px rgba(204, 204, 204, 0.1);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.24);
    :deep(.el-input__wrapper) {
      background-color: transparent !important;
      .el-input__inner {
        color: #fff;
      }
    }
    .title {
      font-size: 30px;
      text-align: center;
      color: #fff;
      margin-bottom: 30px;
    }
    :deep(.el-form-item__label) {
      color: #fff;
    }
  }
  .login-btn {
    text-align: center;
    margin-top: 30px;
    margin-left: 30px;
  }
}
</style>
