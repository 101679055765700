import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import store from "./store";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "./assets/style-common.css";
import { dynamicAddRoutes } from "./router/index";
import { getMenus } from "./units/user";

getMenus();
dynamicAddRoutes();
const app = createApp(App);
app.use(ElementPlus, { locale: zhCn });
app.use(store).use(router).mount("#app");
