import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/login/login.vue";
import Layout from "../layout/index.vue";
import { getToken } from "../units/user";

const routes = [
  {
    path: "/:catchAll(.*)",
    component: () => import("@/views/error/404.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export function dynamicAddRoutes() {
  if (!getToken()) {
    router.replace("/login");
    return;
  }
  const menuList = JSON.parse(localStorage.getItem("menuList"));
  const addRoutes = {
    path: "/",
    name: "layout",
    redirect: "/dashboard",
    component: Layout,
    children: [],
  };
  addRoutes.children = menuList.map((menu) => {
    return {
      path: menu.path,
      name: menu.pathName,
      component: () => import(`../views/${menu.path}/index.vue`),
    };
  });
  router.addRoute(addRoutes);
}

export default router;
