/*
 * @version:
 * @Author: YouHui
 * @Date: 2023-06-17 12:34:46
 * @LastEditors: Andy
 * @LastEditTime: 2023-06-22 00:05:22
 * @Descripttion:
 */
import axios from "axios";
import { ElMessage } from "element-plus";
import { getToken } from "./user";
import baseUrl from "@/config/index";

const HttpRequest = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
});

//请求拦截
HttpRequest.interceptors.request.use((config) => {
  config.headers.token = getToken();
  console.log("====", config);
  return config;
});

// 响应拦截器
HttpRequest.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      let res = response.data;
      if (res.success) {
        return res;
      } else {
        return ElMessage.error(res.message);
      }
    } else {
      return ElMessage.error("网络异常，请稍后重试");
    }
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default HttpRequest;
